import React from 'react';
import ReactMarkdown from 'react-markdown';
import Typewriter from 'typewriter-effect';
import PhoneInputComponent from './PhoneInput';

const Hero = (props) => {
  const { data, currentNodeLocale, qrCodeSection } = props;

  return (
    <section className="section">
      <div className="container">
        <div
          className={
            data.image
              ? 'md:grid md:gap-md md:grid-cols-2 items-center text-center md:text-left'
              : 'text-center lg:m-auto lg:w-3/5'
          }
        >
          <div>
            <div className="lead md:mb-md">
              <div className="heading-typewriter">
                <ReactMarkdown
                  source={data.title.childMarkdownRemark.rawMarkdownBody}
                  disallowedTypes={['paragraph']}
                  unwrapDisallowed={true}
                />
                &nbsp;
                <br />
                {data.typewriter && (
                  <div className="typewriter">
                    <Typewriter
                      options={{
                        strings: data.typewriter,
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </div>
                )}
              </div>
              {data.content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.content.childMarkdownRemark.html,
                  }}
                />
              )}
            </div>
            {data.phoneInput && (
              <div className="heroPhoneInput hidden lg:block">
                <PhoneInputComponent phoneInputData={data.phoneInput} currentNodeLocale={currentNodeLocale} />
              </div>
            )}
            {/* QR Code section */}
            {qrCodeSection && (
              <div className="md:flex md:mt-4">
                {qrCodeSection.images.map((image, index) => {
                  return (
                    <div key={index} className="app-buttons__item">
                      <a href={qrCodeSection.imageLinks[index]}>
                        <img src={image.file.url} key={index} alt={image.description ? image.description : ''} />
                      </a>
                    </div>
                  );
                })}
                <div
                  className="md:my-auto mt-4 md:ml-4 md:max-w-md"
                  dangerouslySetInnerHTML={{
                    __html: qrCodeSection.content.childMarkdownRemark.html,
                  }}
                />
              </div>
            )}
          </div>
          {data.image && (
            <div>
              <img
                className="mx-auto"
                src={data.image.file.url}
                alt={data.image.description ? data.image.description : ''}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Hero;
